var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('b-overlay',{attrs:{"show":_vm.loadingEquityStatistics,"rounded":"sm","no-fade":""}},[_c('b-card',{staticClass:"card-transaction statistic-cards",attrs:{"title":_vm.$t('dashboard.equity_statistics')}},[_c('b-card-body',[_c('div',{staticClass:"transaction-item d-flex flex-wrap"},[_c('b-col',{staticClass:"d-flex flex-column align-items-center",staticStyle:{"gap":"5px"},attrs:{"cols":"1"}},[_c('small',{staticStyle:{"color":"silver"}},[_vm._v(_vm._s(_vm.$t("dashboard.equity_dashboard.order")))]),_c('b-avatar',{staticStyle:{"background-color":"silver !important"},attrs:{"rounded":"","size":"42","variant":"primary","text":_vm.equityBondsCount
                  ? _vm.equityBondsCount.orderCount.toString()
                  : '0'}})],1),_c('b-col',{staticClass:"d-flex flex-column align-items-center",staticStyle:{"gap":"5px"},attrs:{"cols":"1"}},[_c('small',{staticStyle:{"color":"paleturquoise"}},[_vm._v(_vm._s(_vm.$t("dashboard.equity_dashboard.execution")))]),_c('b-avatar',{staticStyle:{"background-color":"paleturquoise !important"},attrs:{"rounded":"","size":"42","variant":"primary","text":_vm.equityBondsCount
                  ? _vm.equityBondsCount.executionCount.toString()
                  : '0'}})],1),_c('b-col',{staticClass:"d-flex flex-column align-items-center",staticStyle:{"gap":"5px"},attrs:{"cols":"1"}},[_c('small',{staticStyle:{"color":"springgreen"}},[_vm._v(_vm._s(_vm.$t("dashboard.equity_dashboard.verify")))]),_c('b-avatar',{staticStyle:{"background-color":"springgreen !important"},attrs:{"rounded":"","size":"42","variant":"primary","text":_vm.equityBondsCount
                  ? _vm.equityBondsCount.verifyCount.toString()
                  : '0'}})],1),_c('b-col',{staticClass:"d-flex flex-column align-items-center",staticStyle:{"gap":"5px"},attrs:{"cols":"1"}},[_c('small',{staticStyle:{"color":"mediumpurple","white-space":"nowrap"}},[_vm._v(_vm._s(_vm.$t("dashboard.equity_dashboard.journal")))]),_c('b-avatar',{staticStyle:{"background-color":"mediumpurple !important"},attrs:{"rounded":"","size":"42","variant":"primary","text":_vm.equityBondsCount
                  ? _vm.equityBondsCount.journalCount.toString()
                  : '0'}})],1),_c('b-col',{staticClass:"d-flex flex-column align-items-center",staticStyle:{"gap":"5px"},attrs:{"cols":"1"}},[_c('small',{staticStyle:{"color":"rgb(241 235 0)","white-space":"nowrap"}},[_vm._v(_vm._s(_vm.$t("dashboard.equity_dashboard.revers_initiated")))]),_c('b-avatar',{staticStyle:{"background-color":"rgb(241 235 0) !important"},attrs:{"rounded":"","size":"42","variant":"primary","text":_vm.equityBondsCount
                  ? _vm.equityBondsCount.reversalInitiatedCount.toString()
                  : '0'}})],1),_c('b-col',{staticClass:"d-flex flex-column align-items-center",staticStyle:{"gap":"5px"},attrs:{"cols":"1"}},[_c('small',{staticStyle:{"color":"coral"}},[_vm._v(_vm._s(_vm.$t("dashboard.equity_dashboard.reversed")))]),_c('b-avatar',{staticStyle:{"background-color":"#f8ac91 !important"},attrs:{"rounded":"","size":"42","variant":"primary","text":_vm.equityBondsCount
                  ? _vm.equityBondsCount.reversedCount.toString()
                  : '0'}})],1)],1),_c('div',{staticClass:"transaction-item"},[_c('b-col',{staticClass:"d-flex flex-column align-items-center",staticStyle:{"gap":"5px"},attrs:{"cols":"1"}},[_c('small',{staticStyle:{"color":"orangered","white-space":"nowrap"}},[_vm._v(_vm._s(_vm.$t("dashboard.equity_dashboard.cancelled")))]),_c('b-avatar',{staticStyle:{"background-color":"orangered !important"},attrs:{"rounded":"","size":"42","variant":"primary","text":_vm.equityBondsCount ? _vm.equityBondsCount.cancelled.toString() : '0'}})],1)],1)]),_c('div',{staticClass:"transaction-item justify-content-end"},[_c('span',{staticClass:"text-muted "},[_vm._v("Total Trades: "+_vm._s(_vm.equityBondsCount ? _vm.equityBondsCount.totalEntries : 0))])])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }