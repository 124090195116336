<template>
  <div>
    <b-overlay :show="loadingFxStatistics" rounded="sm" no-fade>
      <b-card
        class="card-transaction statistic-cards"
        :title="$t('dashboard.fx_statistics')"
      >
        <b-card-body>
          <div class="transaction-item d-flex flex-wrap">
            <!-- İlk Satır -->
            <b-col
              cols="1"
              class="d-flex flex-column align-items-center"
              style="gap:5px;"
            >
              <small style="color: silver;">{{
                $t("dashboard.fx_dashboard.order")
              }}</small>
              <b-avatar
                rounded
                size="42"
                variant="primary"
                style="background-color: silver !important;"
              >
                {{ fxCount ? fxCount.orderCount.toString() : "0" }}
              </b-avatar>
            </b-col>

            <b-col
              cols="1"
              class="d-flex flex-column align-items-center"
              style="gap:5px;"
            >
              <small style="color: paleturquoise;">{{
                $t("dashboard.fx_dashboard.execution")
              }}</small>
              <b-avatar
                rounded
                size="42"
                variant="primary"
                style="background-color: paleturquoise !important;"
              >
                {{ fxCount ? fxCount.executionCount.toString() : "0" }}
              </b-avatar>
            </b-col>

            <b-col
              cols="1"
              class="d-flex flex-column align-items-center"
              style="gap:5px;"
            >
              <small style="color: springgreen;">{{
                $t("dashboard.fx_dashboard.verify")
              }}</small>
              <b-avatar
                rounded
                size="42"
                variant="primary"
                style="background-color: springgreen !important;"
              >
                {{ fxCount ? fxCount.verifyCount.toString() : "0" }}
              </b-avatar>
            </b-col>

            <b-col
              cols="1"
              class="d-flex flex-column align-items-center"
              style="gap:5px;"
            >
              <small style="color: mediumpurple;white-space: nowrap;">{{
                $t("dashboard.fx_dashboard.journal")
              }}</small>
              <b-avatar
                rounded
                size="42"
                variant="primary"
                style="background-color: mediumpurple !important;"
              >
                {{ fxCount ? fxCount.journalCount.toString() : "0" }}
              </b-avatar>
            </b-col>

            <!-- İkinci Satır -->
            <b-col
              cols="1"
              class="d-flex flex-column align-items-center"
              style="gap:5px;"
            >
              <small style="color: rgb(241 235 0);white-space: nowrap;">{{
                $t("dashboard.fx_dashboard.revers_initiated")
              }}</small>
              <b-avatar
                rounded
                size="42"
                variant="primary"
                style="background-color: rgb(241 235 0) !important;"
              >
                {{ fxCount ? fxCount.reversalInitiatedCount.toString() : "0" }}
              </b-avatar>
            </b-col>

            <b-col
              cols="1"
              class="d-flex flex-column align-items-center"
              style="gap:5px;"
            >
              <small style="color: coral;">{{
                $t("dashboard.fx_dashboard.reversed")
              }}</small>
              <b-avatar
                rounded
                size="42"
                variant="primary"
                style="background-color: #f8ac91 !important;"
              >
                {{ fxCount ? fxCount.reversedCount.toString() : "0" }}
              </b-avatar>
            </b-col>
          </div>

          <div class="transaction-item">
            <b-col
              cols="1"
              class="d-flex flex-column align-items-center"
              style="gap:5px;"
            >
              <small style="color: orangered; white-space: nowrap;">{{
                $t("dashboard.equity_dashboard.cancelled")
              }}</small>
              <b-avatar
                rounded
                size="42"
                variant="primary"
                style="background-color: orangered !important;"
                :text="fxCount ? fxCount.cancelled.toString() : '0'"
              ></b-avatar>
            </b-col>
          </div>
        </b-card-body>

        <div class="transaction-item justify-content-end">
          <span class="text-muted"
            >Total Trades: {{ fxCount ? fxCount.totalEntries : 0 }}</span
          >
        </div>
      </b-card>
    </b-overlay>
  </div>
</template>

<script>
import {
  BOverlay,
  BCard,
  BRow,
  BCol,
  BFormInput,
  BButton,
  BTable,
  BMedia,
  BAvatar,
  BLink,
  BBadge,
  BDropdown,
  BDropdownItem,
  BPagination,
  BFormRating,
  VBModal,
  BTabs,
  BTab,
  BCardText,
  BIcon,
  BPopover,
  BFormTimepicker,
  VBTooltip,
} from "bootstrap-vue";

import { kFormatter } from "@core/utils/filter";
import moment from "moment";
import dashboardStoreModule from "./dashboardStoreModule";
import store from "@/store";
import { ref, onUnmounted, reactive } from "@vue/composition-api";
import useJwt from "@/auth/jwt/useJwt";
import router from "@/router";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";

export default {
  components: {
    BOverlay,
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BMedia,
    BAvatar,
    BLink,
    BBadge,
    BTabs,
    BTab,
    BCardText,
    BDropdown,
    BDropdownItem,
    BPagination,
    BFormRating,
    BIcon,
    BPopover,
    BFormTimepicker,

    ToastificationContent,
  },

  setup() {
    const DASHBOARD_APP_STORE_MODULE_NAME = "dashboard";
    // Register module
    if (!store.hasModule(DASHBOARD_APP_STORE_MODULE_NAME))
      store.registerModule(
        DASHBOARD_APP_STORE_MODULE_NAME,
        dashboardStoreModule
      );
    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(DASHBOARD_APP_STORE_MODULE_NAME))
        store.unregisterModule(DASHBOARD_APP_STORE_MODULE_NAME);
    });
  },
  mounted() {},

  created() {
    // this.$store.commit('appConfig/UPDATE_NAV_MENU_HIDDEN', true);
  },

  destroyed() {
    // this.$store.commit('appConfig/UPDATE_NAV_MENU_HIDDEN', false);
  },

  props: {
    fxCount: {
      type: Object,
      default: {
        cancelled: 0,
        executionCount: 0,
        journalCount: 0,
        orderCount: 0,
        reversalInitiatedCount: 0,
        reversedCount: 0,
        totalEntries: 0,
        verifyCount: 0,
      },
    },
  },

  data() {
    const dateNow = moment(new Date()).format("YYYY-MM-DD");

    return {
      dateNow,
      timeLineArray: [],
      formShow: false,
      loadingFxStatistics: false,
      seeFxStatistics: false,
    };
  },

  computed: {
    user: {
      get() {
        return this.$store.getters["authUser"];
      },
      set(newValue) {
        return this.$store.dispatch("setUser", newValue);
      },
    },
  },

  methods: {
    kFormatter,

    dateFormat(val) {
      return moment(val).format("DD-MM-YYYYY HH:mm");
    },
  },
};
</script>
